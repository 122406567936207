import React, { useContext, useCallback, useState, useEffect } from 'react';
import Dialog from '@mui/material/Dialog';

import UserContext from 'contexts/UserContext';
import NotificationContext from 'contexts/NotificationContext';
import AvatarUpload from 'components/AvatarUpload/AvatarUpload';

import betterTrim from '../../utils/betterTrim';
import Button from '../Button/Button';
import Input from '../Input/Input';
import s from './UserInfo.module.scss';

const TITLE_MAX_LENGTH = 50;
const TITLE_MIN_LENGTH = 2;
const DISPLAY_NAME_MAX_LENGTH = 50;
const DISPLAY_NAME_MIN_LENGTH = 2;

const UserInfo = () => {
  const {
    user,
    profileDialogVisible,
    setProfileDialogVisible,
    updateMyUserDetails,
  } = useContext(UserContext);
  const [newDisplayName, setNewDisplayName] = useState(user?.displayName);
  const [newTitle, setNewTitle] = useState(user?.title);
  const [newAvatarPath, setNewAvatarPath] = useState(user?.avatar?.path);
  const [isAvatarLoading, setIsAvatarLoading] = useState(false);
  const avatarPath = user?.avatar?.path;

  useEffect(() => {
    setNewAvatarPath(avatarPath);
  }, [avatarPath]);

  const { flashNotification } = useContext(NotificationContext);

  // To ensure state for displayName and title is updated after login
  useEffect(() => {
    setNewDisplayName(user?.displayName);
    setNewTitle(user?.title);
  }, [user]);

  const displayNameIsTooLong =
    betterTrim(newDisplayName)?.length > DISPLAY_NAME_MAX_LENGTH;
  const displayNameIsTooShort =
    betterTrim(newDisplayName)?.length < DISPLAY_NAME_MIN_LENGTH;
  const titleIsTooLong = betterTrim(newTitle)?.length > TITLE_MAX_LENGTH;
  const titleIsTooShort =
    betterTrim(newTitle)?.length > 0 &&
    betterTrim(newTitle)?.length < TITLE_MIN_LENGTH;

  const submitDisabled =
    isAvatarLoading ||
    titleIsTooLong ||
    titleIsTooShort ||
    displayNameIsTooLong ||
    displayNameIsTooShort;

  const displayNameError = displayNameIsTooLong
    ? `Namnet är för långt. Maxlängd är ${DISPLAY_NAME_MAX_LENGTH} tecken.`
    : displayNameIsTooShort
    ? `Namnet är för kort. Minlängd är ${DISPLAY_NAME_MIN_LENGTH} tecken.`
    : '';

  const titleError = titleIsTooLong
    ? `Texten är för lång. Maxlängd är ${TITLE_MAX_LENGTH} tecken.`
    : titleIsTooShort
    ? `Texten är för kort. Minlängd är ${TITLE_MIN_LENGTH} tecken.`
    : '';

  const updateUserDetails = async () => {
    const updateSuccess = await updateMyUserDetails({
      displayName: betterTrim(newDisplayName),
      title: betterTrim(newTitle),
      avatarPath: betterTrim(newAvatarPath),
    });

    if (updateSuccess) {
      setProfileDialogVisible(false);
    } else {
      flashNotification({
        type: 'error',
        message: 'Ändringen misslyckades. Försök igen.',
      });
    }
  };

  const cancel = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      setNewDisplayName(user?.displayName);
      setNewTitle(user?.title);
      setNewAvatarPath(user?.avatar?.path);
      setProfileDialogVisible(false);
    },
    [user, setProfileDialogVisible]
  );

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();

    if (!submitDisabled) {
      updateUserDetails();
    }
  };

  const handleDisplayNameChange = (event) => {
    setNewDisplayName(event.target.value);
  };

  const handleTitleChange = (event) => {
    setNewTitle(event.target.value);
  };

  const displayAdGroupWarning =
    Boolean(Number(process.env.REACT_APP_AD_GROUP_WARNING_ENABLED)) &&
    user?.adGroups?.length === 0;

  return (
    <Dialog open={profileDialogVisible} fullWidth={true} maxWidth="xs">
      <div className={s.root}>
        <div className={s.head}>SVT</div>
        {displayAdGroupWarning && (
          <div className={s.warning}>
            <strong>{'Obs! Åtgärd krävs omgående: '}</strong>
            {
              'Du har fortfarande inte de behörigheter som krävs för att använda Direktcenter. Inom kort kommer du inte längre kunna logga in. Kontakta din närmaste chef.'
            }
          </div>
        )}
        <div className={s.body}>
          <h2>Byline</h2>
          <form onSubmit={handleSubmit}>
            <AvatarUpload
              onChange={setNewAvatarPath}
              path={newAvatarPath}
              changeLoadingState={(loading) => {
                setIsAvatarLoading(loading);
              }}
            />
            <Input
              id="displayName"
              name="displayName"
              label="Namn"
              type="text"
              defaultValue={newDisplayName}
              onChange={handleDisplayNameChange}
              error={displayNameError}
              required
              autoFocus
            />
            <Input
              id="title"
              name="title"
              label="Titel, plats (frivilligt)"
              type="text"
              defaultValue={newTitle}
              onChange={handleTitleChange}
              error={titleError}
              placeholder="Ex. EU-reporter, i studion"
            />

            <div className={s.footer}>
              <Button onClick={cancel} type="button" variant="secondary">
                Avbryt
              </Button>
              <Button variant="primary" type="submit" disabled={submitDisabled}>
                Fortsätt
              </Button>
            </div>
          </form>
        </div>
      </div>
    </Dialog>
  );
};

export default UserInfo;
