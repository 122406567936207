import React, { useEffect, useState, useCallback } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';

import useStream from 'hooks/useStream';
import withAuthentication from 'components/withAuthentication';
import withAdminOrEditorAuthorization from 'components/withAdminOrEditorAuthorization';
import Module from 'components/Module/Module';
import api from '../services/api';
import PageTitle from 'components/PageTitle/PageTitle';
import PageContent from 'components/PageContent/PageContent';
import PageMainContainer from 'components/PageMainContainer/PageMainContainer';

import s from './StatisticsPage.module.scss';

// TODO Remove when API is in TypeScript
type pendingPostStatisticsResponse = {
  data: {
    statistics: StatisticsAttribute[];
  };
};

type StatisticsAttribute = {
  status: 'TOTAL' | 'UNIQUE_VISITOR_POSTERS';
  count: number;
};

type streamType = {
  createdAt: string;
  isActive: boolean;
  isVisitorPostingEnabled: false;
  parentSection: string;
  section: string;
  title: string;
};

const StatisticsPage = () => {
  const { streamId } = useParams();
  const streamData = useStream(streamId);
  // TODO: Remove this typing when useStream is TypeScript
  const stream = streamData[0] as streamType;
  const loading = streamData[1] as boolean;

  const [statisticsForStream, setStatisticsForStream] = useState<
    StatisticsAttribute[]
  >([]);

  const listStatisticsForStreamId = async () => {
    const { data } = (await api.pendingPostStatistics(
      streamId
    )) as pendingPostStatisticsResponse;

    if (Array.isArray(data.statistics)) {
      setStatisticsForStream(data.statistics);
    }
  };

  const getCountForStatus = useCallback(
    (status: string) => {
      const statisticsObj = statisticsForStream.find(
        (item) => item.status === status
      );
      return statisticsObj ? statisticsObj.count : 0;
    },
    [statisticsForStream]
  );

  useEffect(() => {
    listStatisticsForStreamId();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const title = `Statistik för strömmen '${stream?.title}'`;

  const filteredCount =
    getCountForStatus('blockedCustom') + getCountForStatus('blockedAuto');

  const hiddenCount = filteredCount + getCountForStatus('duplicate');

  return (
    <PageContent>
      <PageMainContainer loading={loading}>
        <Helmet title={title} />
        <PageTitle>{title}</PageTitle>
        <Module>
          <h2>Statistik för besökarinlägg</h2>
          <ul className={s.summaryList}>
            <li>
              <strong>Antal unika besökare som skickat inlägg: </strong>
              <span className={s.count}>
                {getCountForStatus('UNIQUE_VISITOR_POSTERS')}
              </span>
            </li>
            <li>
              <strong>Totalt antal inkomna besökarinlägg: </strong>
              <span className={s.count}>{getCountForStatus('TOTAL')}</span>
              <br />
              Varav <span className={s.count}>{hiddenCount}</span> har
              filtrerats bort
            </li>
            <li>
              <strong>
                Antal publicerade besökarinlägg (med eller utan svar):{' '}
              </strong>
              <span className={s.count}>{getCountForStatus('published')}</span>
            </li>
          </ul>
          <h3>Detaljer</h3>
          De bortfiltrerade inkommande besökarinläggen består av:
          <ul className={s.detailList}>
            <li>
              <span className={s.count}>{filteredCount}</span> inlägg från
              blockerade besökare
            </li>
            <li>
              <span className={s.count}>{getCountForStatus('duplicate')}</span>{' '}
              upprepade inlägg från samma besökare
            </li>
          </ul>
          <p>
            <em>
              För frågor kring statistik, som t.ex. antal unika aktiva besökare,
              hänvisar vi till respektive klient som exempelvis Duo.
            </em>
          </p>
        </Module>
        <Module>
          <a href={`/streams/${streamId}`}>Tillbaka till strömmen</a>
        </Module>
      </PageMainContainer>
    </PageContent>
  );
};

export default withAuthentication(
  withAdminOrEditorAuthorization(StatisticsPage)
);
