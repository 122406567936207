import { useState } from 'react';
import { DirektcenterPost } from '../../Types';
import AnimateIn from 'components/AnimateIn/AnimateIn';
import HighlightItem from './HighlightItem';
import { ReactComponent as DownArrowIcon } from 'img/arrow-down.svg';

import styles from './Highlights.module.scss';

type HighlightsProps = { posts: DirektcenterPost[] };

export default function Highlights({ posts }: HighlightsProps) {
  const [isExpanded, setIsExpanded] = useState<boolean>(true);

  if (!posts.length) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.headingWrapper}>
        <h3 className={styles.heading}>Överblick</h3>
        <button
          className={styles.button}
          onClick={() => setIsExpanded(!isExpanded)}
          type="button"
        >
          {isExpanded ? (
            <>
              Dölj <DownArrowIcon className={styles.buttonIconUp} />
            </>
          ) : (
            <>
              Visa <DownArrowIcon className={styles.buttonIconDown} />
            </>
          )}
        </button>
      </div>

      <AnimateIn isVisible={isExpanded} animateInOnMount={false}>
        <ol className={styles.list}>
          {posts.map((post) => (
            <HighlightItem key={post.id} post={post} />
          ))}
        </ol>
      </AnimateIn>
    </div>
  );
}
