import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import jwtDecode from 'jwt-decode';

import MoreActions from 'components/MoreActions/MoreActions';
import PostEditorContainer from 'components/PostEditor/PostEditorContainer';
import PostContent from 'components/Post/PostContent';
import Byline from 'components/Post/PostByline';
import UserContext from 'contexts/UserContext';
import { VISITOR, GUEST, EDITOR, ADMIN } from 'utils/roles';

import s from './Reply.module.scss';

const Reply = ({
  reply,
  removeReply,
  updateReply,
  setIsEditing,
  unsetIsEditing,
  isEditing,
  isEditable,
}) => {
  const verifySignatureEnabled = Boolean(
    Number(process.env.REACT_APP_VERIFY_SIGNATURE_ENABLED)
  );

  const PotentialReplyContent = !isEditing && (
    <React.Fragment>
      <div className={s.content}>
        <PostContent post={reply} indent />
      </div>
      <Byline author={reply.author} inReply />
    </React.Fragment>
  );

  const PotentialReplyEditor = isEditing && (
    <PostEditorContainer
      post={reply}
      onCancel={unsetIsEditing}
      onSubmit={updateReply}
      allowAttachment={false}
      title="Redigera svar"
      attachmentEnabled={false}
      submitText="Uppdatera"
      cancelText="Avbryt"
    />
  );

  const PotentialActions = () => {
    const { isAdminOrEditor, isGuest, user } = useContext(UserContext);

    if (isEditing || !isEditable) {
      return null;
    }

    const isGuestReply = reply.author.role === GUEST;

    let wasCreatedByCurrentUser = true;

    // TODO: clean up this once toggle REACT_APP_VERIFY_SIGNATURE_ENABLED has been removed
    if (verifySignatureEnabled) {
      const replySignature = reply.signature
        ? jwtDecode(reply.signature)
        : null;
      wasCreatedByCurrentUser = replySignature
        ? replySignature.authorId === user.id
        : false;
    }

    const editAction = {
      title: 'Redigera svar',
      callback: setIsEditing,
    };

    const removeAction = {
      title: 'Ta bort svar',
      callback: removeReply,
      danger: true,
    };

    const adminOrEditorActions = [editAction, removeAction];

    const guestActions =
      isGuestReply && wasCreatedByCurrentUser ? [editAction] : null;

    if (isAdminOrEditor || (isGuest && isGuestReply && guestActions)) {
      return (
        <div className={s.actionsWrapper}>
          <MoreActions
            id={reply.id}
            buttonLabel="Hantera svar"
            actions={isAdminOrEditor ? adminOrEditorActions : guestActions}
          />
        </div>
      );
    }

    return null;
  };

  return (
    <div
      className={cn(s.root, {
        [s.isEditing]: isEditing,
      })}
    >
      <PotentialActions />
      {PotentialReplyContent}
      {PotentialReplyEditor}
    </div>
  );
};

Reply.propTypes = {
  reply: PropTypes.shape({
    id: PropTypes.string.isRequired,
    body: PropTypes.string.isRequired,
    createdAt: PropTypes.string,
    signature: PropTypes.string,
    author: PropTypes.shape({
      displayName: PropTypes.string,
      role: PropTypes.oneOf([VISITOR, GUEST, EDITOR, ADMIN]),
      title: PropTypes.string,
      avatar: PropTypes.shape({
        path: PropTypes.string,
      }),
    }),
  }).isRequired,
  removeReply: PropTypes.func.isRequired,
  updateReply: PropTypes.func.isRequired,
  setIsEditing: PropTypes.func.isRequired,
  unsetIsEditing: PropTypes.func.isRequired,
  isEditing: PropTypes.bool.isRequired,
  isEditable: PropTypes.bool.isRequired,
};

export default Reply;
