import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import AnimateIn from 'components/AnimateIn/AnimateIn';

import ShowForAdminOrEditor from 'components/ShowForAdminOrEditor/ShowForAdminOrEditor';
import UpdateStreamEditorContainer from 'components/StreamEditor/UpdateStreamEditorContainer';
import StreamMetadataContainer from 'components/StreamEditor/StreamMetadataContainer';

import GuestsContainer from 'components/GuestsEditor/GuestsContainer';
import StreamInfo from './_StreamInfo';
import SettingsContainer from './_SettingsContainer';
import useDirektcenterMetadata from 'hooks/useDirektcenterMetadata';

const StreamMeta = ({ stream, streamId, onStreamDeleted }) => {
  const [showSettings, setShowSettings] = useState(false);
  const [showGuestSettings, setShowGuestSettings] = useState(false);
  const [showMetadata, setShowMetadata] = useState(false);

  const metadata = useDirektcenterMetadata(streamId);

  const toggleSettings = useCallback(() => {
    setShowSettings(!showSettings);
    setShowGuestSettings(false);
    setShowMetadata(false);
  }, [showSettings]);

  const toggleGuestSettings = useCallback(() => {
    setShowGuestSettings(!showGuestSettings);
    setShowSettings(false);
    setShowMetadata(false);
  }, [showGuestSettings]);

  const toggleMetadata = useCallback(() => {
    setShowMetadata(!showMetadata);
    setShowSettings(false);
    setShowGuestSettings(false);
  }, [showMetadata]);

  const onClose = () => {
    setShowGuestSettings(false);
    setShowSettings(false);
    setShowMetadata(false);
  };

  const guestsEnabled = Boolean(Number(process.env.REACT_APP_GUESTS_ENABLED));

  return (
    <React.Fragment>
      <StreamInfo
        streamId={streamId}
        stream={stream}
        showSettings={showSettings}
        showGuestSettings={showGuestSettings}
        showMetadataContainer={showMetadata}
        guestSettingsEnabled={guestsEnabled}
        toggleSettings={toggleSettings}
        toggleGuestSettings={toggleGuestSettings}
        toggleMetadata={toggleMetadata}
      />
      <ShowForAdminOrEditor>
        <AnimateIn isVisible={showMetadata}>
          <SettingsContainer onClose={onClose} enableClose={stream.isActive}>
            <StreamMetadataContainer metadata={metadata} streamId={streamId} />
          </SettingsContainer>
        </AnimateIn>
      </ShowForAdminOrEditor>

      <AnimateIn isVisible={showGuestSettings}>
        <SettingsContainer onClose={onClose} enableClose={true}>
          <GuestsContainer
            streamId={streamId}
            showGuestSettings={showGuestSettings}
          />
        </SettingsContainer>
      </AnimateIn>

      <ShowForAdminOrEditor>
        <AnimateIn isVisible={showSettings || !stream.isActive}>
          <SettingsContainer onClose={onClose} enableClose={stream.isActive}>
            <UpdateStreamEditorContainer
              streamId={streamId}
              stream={stream}
              onDidCancel={() => setShowSettings(false)}
              onDidUpdate={() => setShowSettings(false)}
              onDidDelete={onStreamDeleted}
            />
          </SettingsContainer>
        </AnimateIn>
      </ShowForAdminOrEditor>
    </React.Fragment>
  );
};

StreamMeta.propTypes = {
  stream: PropTypes.object.isRequired,
  streamId: PropTypes.string.isRequired,
  onStreamDeleted: PropTypes.func.isRequired,
};

export default StreamMeta;
