import React from 'react';
import PropTypes from 'prop-types';
import {
  createSectionData,
  validateParentSection,
  validateSection,
} from 'utils/streamSection';

import Input from 'components/Input/Input';
import CopyToClipboardField from 'components/CopyToClipboardField/CopyToClipboardField';
import s from './StreamEditor.module.scss';
import SectionSelect from 'components/SectionSelect';

const DEFAULT_VALUE = 'default';

const StreamEditor = ({ stream, streamId, onChange, title }) => {
  const onChangeSection = (e) => {
    const formIsValid = e.target.value !== DEFAULT_VALUE;

    if (formIsValid) {
      onChange({
        ...stream,
        ...createSectionData(e.target.value),
      });
    }
  };

  const isHighlightsFeatureEnabled = [
    'Riks',
    'Lokalt',
    'Sport',
    'Test',
  ].includes(stream.parentSection);

  const getValue = () => {
    const { parentSection, section } = stream;

    const isValidParentSection =
      validateParentSection(parentSection) === parentSection;
    const isValidSection = validateSection(section) === section;

    if (isValidParentSection && isValidSection) {
      if (!section) {
        return parentSection;
      }

      return section;
    }

    return DEFAULT_VALUE;
  };

  return (
    <React.Fragment>
      {title && <h2 className={s.header}>{title}</h2>}
      <Input
        name="title"
        label="Publik strömtitel"
        value={stream.title}
        maxLength={100}
        data-testid="streamSettingsTitleInput"
        required
        autoFocus
        onChange={(e) => onChange({ ...stream, title: e.target.value })}
      />

      <SectionSelect
        value={getValue()}
        onChange={onChangeSection}
        label="Avdelning"
        defaultValue={DEFAULT_VALUE}
        defaultString="Välj en avdelning..."
        required
      />

      {streamId && (
        <CopyToClipboardField
          label="Strömmens id (för Astrid, Edith och Monterosa)"
          value={streamId}
          buttonText="Kopiera id"
        />
      )}

      <fieldset
        className={s.fieldsetElement}
        onChange={(e) => {
          let isActive, isVisitorPostingEnabled;

          switch (e.target.value) {
            case 'activeWithVisitorPosts':
              isActive = true;
              isVisitorPostingEnabled = true;
              break;
            case 'activeWithoutVisitorPosts':
              isActive = true;
              isVisitorPostingEnabled = false;
              break;
            default:
              isActive = false;
              isVisitorPostingEnabled = false;
              break;
          }
          onChange({ ...stream, isActive, isVisitorPostingEnabled });
        }}
      >
        <legend className={s.legend}>Status</legend>
        <label className={s.radioInput}>
          <input
            type="radio"
            name="streamStatus"
            value="activeWithVisitorPosts"
            className={s.radioInputElement}
            defaultChecked={stream.isActive && stream.isVisitorPostingEnabled}
          />
          <div className={s.radioShadowElement} />
          <div className={s.radioLabelText}>Aktiv med besökarinlägg</div>
        </label>
        <label className={s.radioInput}>
          <input
            type="radio"
            name="streamStatus"
            value="activeWithoutVisitorPosts"
            className={s.radioInputElement}
            defaultChecked={
              stream.isActive && stream.isVisitorPostingEnabled === false
            }
          />
          <div className={s.radioShadowElement} />
          <div className={s.radioLabelText}>Aktiv utan besökarinlägg</div>
        </label>
        <label className={s.radioInput}>
          <input
            type="radio"
            name="streamStatus"
            value="inactive"
            className={s.radioInputElement}
            defaultChecked={stream.isActive === false}
          />
          <div className={s.radioShadowElement} />
          <div className={s.radioLabelText}>Inaktiv</div>
        </label>
      </fieldset>

      {isHighlightsFeatureEnabled && (
        <fieldset
          className={s.fieldsetElement}
          onChange={(e) => {
            let isHighlightsBoxVisible;

            switch (e.target.value) {
              case 'isHighlightsBoxVisibleTrue':
                isHighlightsBoxVisible = true;
                break;
              case 'isHighlightsBoxVisibleFalse':
                isHighlightsBoxVisible = false;
                break;
              default:
                isHighlightsBoxVisible = true;
                break;
            }
            onChange({ ...stream, isHighlightsBoxVisible });
          }}
        >
          <legend className={s.legend}>Överblick</legend>
          <label className={s.radioInput}>
            <input
              type="radio"
              name="streamIsHighlightsBoxVisible"
              value="isHighlightsBoxVisibleTrue"
              className={s.radioInputElement}
              defaultChecked={stream.isHighlightsBoxVisible !== false}
            />
            <div className={s.radioShadowElement} />
            <div className={s.radioLabelText}>
              Visa ÖVERBLICK för alla besökare i appen och webben
            </div>
          </label>
          <label className={s.radioInput}>
            <input
              type="radio"
              name="streamIsHighlightsBoxVisible"
              value="isHighlightsBoxVisibleFalse"
              className={s.radioInputElement}
              defaultChecked={stream.isHighlightsBoxVisible === false}
            />
            <div className={s.radioShadowElement} />
            <div className={s.radioLabelText}>
              Dölj ÖVERBLICK för alla besökare i appen och webben
            </div>
          </label>
        </fieldset>
      )}
    </React.Fragment>
  );
};

StreamEditor.propTypes = {
  stream: PropTypes.shape({
    title: PropTypes.string.isRequired,
    isActive: PropTypes.bool.isRequired,
    isVisitorPostingEnabled: PropTypes.bool.isRequired,
    isHighlightsBoxVisible: PropTypes.bool.isRequired,
    parentSection: PropTypes.string,
    section: PropTypes.string,
  }).isRequired,
  onChange: PropTypes.func,
  title: PropTypes.string,
  streamId: PropTypes.string,
};

export default StreamEditor;
