import { useState, useEffect } from 'react';
import firebase from '../services/firebase';

const useHighlightedPosts = (streamId) => {
  const [highlightedPosts, setHighlightedPosts] = useState([]);
  const [hasHighlights, setHasHighlights] = useState(false);

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('streams')
      .doc(streamId)
      .collection('posts')
      .where('highlighted', '==', true)
      .orderBy('createdAt', 'desc')
      .limit(5)
      .onSnapshot((snapshot) => {
        let posts = [];
        snapshot.forEach((doc) => {
          posts.push({ ...doc.data(), id: doc.id });
        });
        setHighlightedPosts(posts);
        setHasHighlights(posts.length > 0);
      });

    return () => unsubscribe();
  }, [streamId]);

  return { hasHighlights, highlightedPosts };
};

export default useHighlightedPosts;
