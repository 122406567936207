import { useContext } from 'react';
import { useParams } from 'react-router-dom';

import Button from 'components/Button/Button';
import ImageEditorContainer from './ImageEditor/ImageEditorContainer';
import SvtVideoEditorContainer from './SvtVideoEditor/SvtVideoEditorContainer';
import WebpageEditorContainer from './WebpageEditor/WebpageEditorContainer';
import s from './NewAttachmentSelector.module.scss';
import {
  PostEditorContext,
  postEditorActions,
} from 'components/PostEditor/PostEditorStore';
import UserContext from 'contexts/UserContext';

const initialDataMap = {
  [ImageEditorContainer.type]: ImageEditorContainer.initialData,
  [WebpageEditorContainer.type]: WebpageEditorContainer.initialData,
  [SvtVideoEditorContainer.type]: SvtVideoEditorContainer.initialData,
};

const NewAttachmentSelector = () => {
  const { isAdminOrEditor } = useContext(UserContext);
  const { streamId } = useParams();

  const isDisasterStream = streamId === process.env.REACT_APP_DISASTER_STREAM;

  const attachmentButtons = isAdminOrEditor
    ? [
        {
          type: ImageEditorContainer.type,
          text: 'Bild',
          disabled: false,
        },
        {
          type: SvtVideoEditorContainer.type,
          text: 'Video',
          disabled: isDisasterStream,
        },
        {
          type: WebpageEditorContainer.type,
          text: 'Artikel',
          disabled: isDisasterStream,
        },
      ]
    : [
        {
          type: ImageEditorContainer.type,
          text: 'Lägg till bild',
          disabled: false,
        },
      ];

  const { dispatcher: postEditorDispatcher } = useContext(PostEditorContext);

  const createAttachmentOfType = (type) => {
    postEditorDispatcher({
      type: postEditorActions.SET_ATTACHMENT,
      payload: initialDataMap[type],
    });
  };

  return attachmentButtons.map((attachmentButton) => (
    <Button
      className={s.attachmentButton}
      key={attachmentButton.type}
      variant="secondary"
      onClick={() => createAttachmentOfType(attachmentButton.type)}
      disabled={attachmentButton.disabled}
    >
      {attachmentButton.text}
    </Button>
  ));
};

export default NewAttachmentSelector;
