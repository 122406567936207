import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import moment from 'moment';

import Timestamp from 'components/Timestamp';
import Tooltip from 'components/Tooltip/Tooltip';
import s from './StreamTable.module.scss';
import AnimatedLiveIcon from 'components/AnimatedLiveIcon/AnimatedLiveIcon';

function PotentialSections({ stream }) {
  const { parentSection, section } = stream;

  if (parentSection) {
    if (section) {
      return `${section} (${parentSection})`;
    }

    return parentSection;
  }

  return null;
}

const streamShape = PropTypes.shape({
  id: PropTypes.string,
  title: PropTypes.string,
  createdAt: PropTypes.string,
  parentSection: PropTypes.string,
  section: PropTypes.string,
});

PotentialSections.propTypes = {
  stream: streamShape,
};

export default function StreamTable({ streams }) {
  if (streams.length <= 0) {
    return null;
  }
  return (
    <table className={s.root}>
      <thead>
        <tr>
          <th scope="col">Publik strömtitel</th>
          <th scope="col">Avdelning</th>
          <th scope="col">Skapad</th>
        </tr>
      </thead>
      <tbody>
        {streams.map((stream) => {
          const mCreatedAt = moment(stream.createdAt);

          const isDisasterStream =
            stream.streamId === process.env.REACT_APP_DISASTER_STREAM;

          const potentialDisasterLabel = isDisasterStream ? (
            <span className={s.disasterLabel}>Nödsajt</span>
          ) : null;

          return (
            <tr key={stream.streamId} className={s.tableRow}>
              <td>
                <div className={s.tableWrapper}>
                  {stream.isActive && (
                    <div className={s.indicatorActive}>
                      <AnimatedLiveIcon />
                      <Tooltip className={s.tooltip}>
                        {stream.isActive ? 'Aktiv' : 'Inaktiv'}
                      </Tooltip>
                    </div>
                  )}
                  {!stream.isActive && (
                    <div className={s.indicator}>
                      <Tooltip className={s.tooltip}>
                        {stream.isActive ? 'Aktiv' : 'Inaktiv'}
                      </Tooltip>
                    </div>
                  )}
                  <Link to={`/streams/${stream.streamId}`}>{stream.title}</Link>
                  {potentialDisasterLabel}
                </div>
              </td>

              <td>
                <PotentialSections stream={stream} />
              </td>

              <td>
                <Timestamp mDate={mCreatedAt} variant="svt" />
              </td>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

StreamTable.propTypes = {
  streams: PropTypes.arrayOf(streamShape),
};
