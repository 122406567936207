import React, { useState, useEffect } from 'react';

import AnimateIn from 'components/AnimateIn/AnimateIn';
import firebase from 'services/firebase';
import s from './ServiceMessage.module.scss';

const ServiceMessage = () => {
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState('');

  const disable = () => {
    setShow(false);
    setMessage('');
  };

  const enable = (message) => {
    setShow(true);
    setMessage(message);
  };

  useEffect(() => {
    const unsubscribe = firebase
      .firestore()
      .collection('metadata')
      .doc('editor')
      .onSnapshot((snap) => {
        const allFieldsExist =
          snap.data()?.show_service_message && snap.data()?.service_message;
        if (allFieldsExist) {
          enable(snap.data().service_message);
        } else {
          disable();
        }
      });

    return () => unsubscribe();
  });

  return (
    <AnimateIn isVisible={show} animateInOnMount={false}>
      <div className={s.root}>
        <div className={s.text}>{message}</div>
      </div>
    </AnimateIn>
  );
};

export default ServiceMessage;
