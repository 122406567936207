import ApiError from 'errors/ApiError';
import BaseError from 'errors/BaseError';
import NetworkError from 'errors/NetworkError';
import UnauthorizedError from 'errors/UnauthorizedError';
import _ from 'lodash';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import queryString from 'query-string';

import {
  MISSING_AD_GROUP_ERROR_MESSAGE,
  NETWORK_ERROR_MESSAGE,
} from 'utils/apiErrorMessages';

const ERROR = 'Error';
const SUCCESS = 'Success';
const USER_DATA = 'UserData';

let accessToken;

axios.interceptors.request.use(
  (config) => {
    if (!config.url.includes(process.env.REACT_APP_API_URL)) {
      return config;
    }

    if (accessToken) {
      config.headers['Authorization'] = `Bearer ${accessToken}`;
    }
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

function publish(handlers, event, args) {
  handlers.forEach((topic) => {
    if (topic.event === event) {
      topic.handler(args);
    }
  });
}

const shouldRetry = (error, originalRequest) => {
  const isADGroupError =
    error?.response?.data?.data[0] === MISSING_AD_GROUP_ERROR_MESSAGE;

  const isUnauthorizedOrForbiddenError =
    error?.response?.status === 401 || error?.response?.status === 403;

  return (
    !isADGroupError && isUnauthorizedOrForbiddenError && !originalRequest._retry
  );
};

class Api {
  constructor() {
    this.userData = null;
    this.baseUrl = process.env.REACT_APP_API_URL;
    this.handlers = [];
    this._updateAccessToken = this._updateAccessToken.bind(this);

    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        const originalRequest = error.config;

        if (shouldRetry(error, originalRequest)) {
          originalRequest._retry = true;
          const response = await fetch(
            `${process.env.REACT_APP_API_URL}/refresh-token`,
            {
              method: 'POST',
              credentials: 'include',
              headers: {
                'Content-Type': 'application/json',
              },
            }
          );

          if (response?.status === 200) {
            const responseData = await response.json();
            this._updateAccessToken(responseData);
            return axios({
              ...originalRequest,
              headers: {
                ...originalRequest.headers,
                Authorization: `Bearer ${accessToken}`,
              },
            });
          } else {
            this._updateAccessToken({ data: { token: null } });
            // throw new UnauthorizedError();
          }
        }

        if (error.message === NETWORK_ERROR_MESSAGE) {
          return Promise.reject(error);
        }

        return Promise.resolve({
          data: {
            status: 'error',
            message: error.message,
            error,
          },
        });
      }
    );
  }

  off() {
    accessToken = undefined;
    this.userData = null;
    this.handlers.splice(0, this.handlers.length);
  }

  on(event, handler, context) {
    if (typeof context === 'undefined') {
      context = handler;
    }

    const eventHandler = { event, handler: handler.bind(context) };

    this.handlers.push(eventHandler);

    const removeEventHandler = () => {
      const index = this.handlers.findIndex(
        (handler) => handler === eventHandler
      );
      if (index > -1) {
        this.handlers.splice(index, 1);
      }
    };

    return removeEventHandler;
  }

  _updateAccessToken(response) {
    const newAccessToken = response?.data?.token;
    if (accessToken !== newAccessToken) {
      if (response === undefined) {
        accessToken = null;
        this.userData = null;
        return publish(this.handlers, USER_DATA, null);
      }

      if (accessToken === undefined && newAccessToken === null) {
        accessToken = null;
        this.userData = null;
        return publish(this.handlers, USER_DATA, null);
      }

      if (newAccessToken === accessToken) {
        return;
      }
      accessToken = newAccessToken;

      const userData = accessToken ? jwtDecode(accessToken) : null;

      if (response.data?.firebaseCredentials) {
        userData.firebaseCredentials = response.data?.firebaseCredentials;
      } else if (userData) {
        userData.firebaseCredentials = this?.userData?.firebaseCredentials;
      }

      if (
        accessToken === undefined ||
        _.isEqual(this.userData, userData) === false
      ) {
        this.userData = userData;
        // TODO: rename event handler name from auth to userData and move to constant.
        publish(this.handlers, USER_DATA, this.userData);
      }
    }
    return response;
  }

  async _req({ url, method, data }) {
    try {
      const response = await axios({
        url: this.baseUrl + url,
        method: method,
        timeout: 1000 * 10, // 10 sec timeout for all API requests
        withCredentials: true,
        data,
        headers: !accessToken
          ? {}
          : {
              Authorization: `Bearer ${accessToken}`,
            },
      });

      if (response.status === 401 || response.status === 403) {
        throw new UnauthorizedError();
      }
      publish(this.handlers, SUCCESS, response.data);

      return response.data;
    } catch (err) {
      let error;
      if (err.message === NETWORK_ERROR_MESSAGE) {
        error = new NetworkError();
      } else if (err instanceof BaseError === false) {
        error = new ApiError(err.message);
      } else {
        error = err;
      }
      publish(this.handlers, ERROR, error);
      //TODO: Change return value from object to instance of an error only
      return {
        status: 'error',
        message: err.message,
        error,
      };
    }
  }

  _post(url, data) {
    return this._req({ method: 'post', url, data });
  }

  _put(url, data) {
    return this._req({ method: 'put', url, data });
  }

  _patch(url, data) {
    return this._req({ method: 'patch', url, data });
  }

  _delete(url) {
    return this._req({ method: 'delete', url });
  }

  _get(url) {
    return this._req({ method: 'get', url });
  }

  async usersList() {
    return await this._get(`/users`);
  }

  // TODO: change to /guests instead?
  async guestsList() {
    return await this._get(`/guest/list`);
  }

  async loginWithGuestToken(token) {
    return this._post('/guest/auth', {
      token,
    }).then(this._updateAccessToken);
  }

  async login({ email, password }) {
    return this._post('/users/auth', {
      email,
      password,
    }).then(this._updateAccessToken);
  }

  async loginWithToken({ token }) {
    return this._post('/users/auth-token', {
      token,
    }).then(this._updateAccessToken);
  }

  async loginWithCurrentSession() {
    const response = await this._post(`/refresh-token`).then(
      this._updateAccessToken
    );
    try {
      const user = jwtDecode(accessToken);
      const { firebaseCredentials } = response?.data;

      return { user, firebaseCredentials };
    } catch {
      return null;
    }
  }

  async updateMyUserDetails(userData) {
    return this._patch('/me', userData).then(this._updateAccessToken);
  }

  async logout() {
    try {
      await this._post('/refresh-token/revoke').then(this._updateAccessToken);
    } catch (err) {
      throw err;
    } finally {
      this._updateAccessToken({ data: { token: accessToken || null } });
    }
  }

  async postDelete(streamId, postId) {
    return await this._delete(`/streams/${streamId}/posts/${postId}`);
  }

  async postUpdate(
    streamId,
    postId,
    { body, attachment, pinned, highlighted, createdAt, author, signature }
  ) {
    return await this._put(`/streams/${streamId}/posts/${postId}`, {
      body,
      attachment,
      pinned,
      highlighted,
      createdAt,
      author,
      signature,
    });
  }

  async postRenew(
    streamId,
    postId,
    { body, attachment, pinned, highlighted, createdAt, author, signature }
  ) {
    return await this._put(`/streams/${streamId}/posts/${postId}/renew`, {
      body,
      attachment,
      pinned,
      highlighted,
      createdAt,
      author,
      signature,
    });
  }

  async postCreate(
    streamId,
    { body, attachment, pinned, highlighted, author }
  ) {
    return await this._post(`/streams/${streamId}/posts`, {
      body,
      attachment,
      pinned,
      highlighted,
      author,
    });
  }

  async pendingPostCount({ streamId, createdAfter }) {
    const queryObject = _.omitBy({ createdAfter }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';
    return await this._get(
      `/streams/${streamId}/pending-posts/count${potentialQuestionMark}${query}`
    );
  }

  async pendingPostList({ streamId, limit, createdAtOrBefore }) {
    const queryObject = _.omitBy({ limit, createdAtOrBefore }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';
    return await this._get(
      `/streams/${streamId}/pending-posts/${potentialQuestionMark}${query}`
    );
  }

  async pendingPostPublish(streamId, postId, reply) {
    return await this._post(
      `/streams/${streamId}/pending-posts/${postId}/publish`,
      {
        reply,
      }
    );
  }

  async pendingPostDecline(streamId, postId) {
    return await this._delete(`/streams/${streamId}/pending-posts/${postId}`);
  }

  async pendingPostSetIsApprovedForGuest(streamId, postId, isApprovedForGuest) {
    return await this._put(`/streams/${streamId}/pending-posts/${postId}`, {
      isApprovedForGuest,
    });
  }

  async pendingPostReportAnnoying(streamId, postId, _isReported) {
    return await this._put(
      `/streams/${streamId}/pending-posts/${postId}/report`,
      {
        reason: 'annoying',
      }
    );
  }

  async pendingPostReportThreat(streamId, postId, _isReported) {
    return await this._put(
      `/streams/${streamId}/pending-posts/${postId}/report`,
      {
        reason: 'threat',
      }
    );
  }

  async pendingPostUpdate(streamId, postId, text) {
    return await this._put(
      `/streams/${streamId}/pending-posts/${postId}/update`,
      text
    );
  }

  async pendingPostListVisitorPosts(streamId, limit, offset) {
    const queryObject = _.omitBy({ limit, offset }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';

    return await this._get(
      `/streams/${streamId}/pending-posts/visitor-posts${potentialQuestionMark}${query}`
    );
  }

  async pendingPostListReported(streamId, limit) {
    const queryObject = _.omitBy({ limit }, _.isUndefined);
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';

    return await this._get(
      `/streams/${streamId}/pending-posts/reported${potentialQuestionMark}${query}`
    );
  }

  async pendingPostListReportedAll(
    limit,
    hideTestStream,
    excludeStatuses = []
  ) {
    const queryObject = _.omitBy(
      {
        limit,
        hideTestStream,
        excludeStatuses: excludeStatuses.join(','),
      },
      _.isUndefined
    );
    const query = queryString.stringify(queryObject);
    const potentialQuestionMark = query.length ? '?' : '';

    return await this._get(
      `/streams/anything/pending-posts/reportedAll${potentialQuestionMark}${query}`
    );
  }

  async pendingPostStatistics(streamId) {
    return await this._get(`/streams/${streamId}/statistics`);
  }

  async streamDelete(id) {
    return await this._delete(`/streams/${id}`);
  }

  async streamUpdate(
    id,
    {
      title,
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      createdAt,
      parentSection,
      section,
    }
  ) {
    return await this._put(`/streams/${id}`, {
      title,
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      createdAt,
      parentSection,
      section,
    });
  }

  async streamCreate({
    title,
    isActive,
    isVisitorPostingEnabled,
    isHighlightsBoxVisible,
    parentSection,
    section,
  }) {
    return await this._post('/streams', {
      title,
      isActive,
      isVisitorPostingEnabled,
      isHighlightsBoxVisible,
      parentSection,
      section,
    });
  }

  async streamSearch(query) {
    const params = new URLSearchParams(query).toString();
    return await this._get(`/streams/search-internal/?${params}`);
  }

  async replyCreate(streamId, postId, { body, attachment, pinned, author }) {
    return await this._post(`/streams/${streamId}/posts/${postId}/replies`, {
      body,
      attachment,
      pinned,
      author,
    });
  }

  async replyRemove(streamId, postId, replyId) {
    return await this._delete(
      `/streams/${streamId}/posts/${postId}/replies/${replyId}`
    );
  }

  async replyUpdate(
    streamId,
    postId,
    replyId,
    { body, attachment, pinned, createdAt, signature, author }
  ) {
    return await this._put(
      `/streams/${streamId}/posts/${postId}/replies/${replyId}`,
      {
        body,
        attachment,
        pinned,
        createdAt,
        signature,
        author,
      }
    );
  }

  async fetchOgData(url) {
    return await this._post('/open-graph', {
      url,
    });
  }

  async fetchVideoData(videoId) {
    return await this._post('/video-data', {
      id: videoId,
    });
  }

  async getFileUploadUrl() {
    return await this._get('/file-upload-url');
  }

  async getAvatarUploadUrl() {
    return await this._get('/avatar-upload-url');
  }

  async guestDetails(token) {
    return await this._post('/guest/details', {
      token,
    });
  }

  async guestCreate(
    streamId,
    { displayName, title, avatarPath, isModerationRequired }
  ) {
    return await this._post(`/streams/${streamId}/guests`, {
      displayName,
      title,
      avatarPath,
      isModerationRequired,
    });
  }

  async guestList(streamId) {
    return await this._get(`/streams/${streamId}/guests`);
  }

  async guestDelete(guestId) {
    await this._delete(`/guest/${guestId}`);
  }

  // TOOD: change to more appropriate name
  async me() {
    this._updateAccessToken({ data: { token: accessToken || null } });
    return this.userData;
  }
}

const api = new Api();

export default api;

const apiEvents = { ERROR, SUCCESS, USER_DATA };

export { apiEvents };
