import React, { useMemo, useCallback, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import api, { apiEvents } from 'services/api';
import firebase from 'services/firebase';
import { ADMIN, EDITOR, GUEST } from 'utils/roles';
import { MISSING_AD_GROUP_ERROR_MESSAGE } from 'utils/apiErrorMessages';
import MissingADGroupError from 'errors/MissingADGroupError';

const UserContext = React.createContext();

const authenticateToFirebase = async ({ email, password }) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(email, password);
  } catch (err) {
    throw new Error('Error authenticating to Firebase:', err);
  }
};

export const UserContextProvider = ({ children }) => {
  const [profileDialogVisible, setProfileDialogVisible] = useState(false);
  const [userData, setUserData] = useState(null);
  const [isLoggingIn, setIsLoggingIn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isRestoring, setIsRestoring] = useState(true);
  const [userRole, setUserRole] = useState(null);
  const [isLoggedInToFirebase, setIsLoggedInToFirebase] = useState(false);

  useEffect(() => {
    const removeListener = api.on(apiEvents.USER_DATA, () => {
      api.me().then(setUserData);
    });
    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    if (userData) {
      setIsLoggingIn(false);
    }
    setUserRole(userData?.role || null);
  }, [userData]);

  useEffect(() => {
    const removeListener = api.on(apiEvents.USER_DATA, async () => {
      const userData = await api.me();
      setUserData(userData);
      setIsLoading(false);
    });

    const init = async () => {
      await api.loginWithCurrentSession();
      const userData = await api.me();
      if (userData) {
        setUserData(userData);
        setIsLoading(false);
      }
      setIsLoading(false);
      setIsRestoring(false);
    };

    init();

    return () => {
      removeListener();
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      if (firebase.auth().currentUser !== null) {
        setIsLoggedInToFirebase(true);
      } else if (userData?.firebaseCredentials) {
        await authenticateToFirebase(userData.firebaseCredentials);
        setIsLoggedInToFirebase(true);
      }
    };
    init();
  }, [userData]);

  const loginWithJwt = useCallback(async ({ token: inputToken }) => {
    if (!inputToken) {
      return { success: false, error: 'Missing input token' };
    }
    setIsLoggingIn(true);
    const response = await api.loginWithToken({ token: inputToken });

    if (
      response?.error?.response?.data?.data[0] ===
      MISSING_AD_GROUP_ERROR_MESSAGE
    ) {
      throw new MissingADGroupError();
    }
    if (response?.status !== 'success') {
      const message = response?.data?.[0];
      throw new Error(message || 'Unknown error');
    }

    const { firebaseCredentials } = response.data;
    await authenticateToFirebase(firebaseCredentials);
    setIsLoggedInToFirebase(true);

    return { success: true };
  }, []);

  const loginWithGuestToken = useCallback(async ({ token: inputToken }) => {
    if (!inputToken) {
      return false;
    }
    setIsLoggingIn(true);
    const response = await api.loginWithGuestToken(inputToken);
    if (response?.status !== 'success') {
      const message = response?.data?.[0];
      if (message !== 'Invalid guest token') {
        throw new Error(message || 'Unknown error');
      }
      return false;
    }
    const { streamId } = response.data;

    return streamId;
  }, []);

  const loginWithEmailAndPassword = useCallback(async ({ email, password }) => {
    setIsLoggingIn(true);
    return api.login({ email, password });
  }, []);

  const updateMyUserDetails = useCallback(
    async ({ displayName, title, avatarPath }) => {
      const response = await api.updateMyUserDetails({
        displayName,
        title,
        avatarPath,
      });

      if (response.status !== 'success') {
        return false;
      }

      return true;
    },
    []
  );

  const logout = useCallback(async () => {
    try {
      setIsLoggingIn(false);
      setIsLoading(false);
      setIsRestoring(false);
      setUserData(null);
      setUserRole(null);
      await api.logout();
      await firebase.auth().signOut();
    } catch {}
  }, []);

  const value = useMemo(
    () => ({
      user: userData,
      role: userRole,
      isLoggingIn,
      isLoggedIn: Boolean(
        userData && (isLoggedInToFirebase || userRole === 'guest')
      ),
      isLoggedInToFirebase,
      isGuest: userData?.role === GUEST,
      isUnmoderatedGuest:
        userData?.role === GUEST && userData?.isModerationRequired === false,
      isAdmin: userData?.role === ADMIN,
      isAdminOrEditor: [ADMIN, EDITOR].includes(userData?.role),
      loginWithGuestToken,
      loginWithEmailAndPassword,
      loginWithJwt,
      logout,
      profileDialogVisible: Boolean(profileDialogVisible && userData),
      setProfileDialogVisible,
      isLoading,
      isRestoring,
      updateMyUserDetails,
    }),
    [
      userData,
      userRole,
      isLoggingIn,
      isLoggedInToFirebase,
      loginWithGuestToken,
      loginWithEmailAndPassword,
      loginWithJwt,
      logout,
      profileDialogVisible,
      isLoading,
      isRestoring,
      updateMyUserDetails,
    ]
  );

  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

UserContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default UserContext;
