import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import 'firebase/compat/auth';
import { Timestamp } from 'firebase/firestore';

var config = {
  apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
  authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
};

export const firebaseApp = firebase.initializeApp(config);
export default firebase;

//
// TODO: when finished migrating to firebase 9 syntax
// https://firebase.google.com/docs/web/modular-upgrade#update_initialization_code
// remove all code above this comment and use the following code instead.
//
// import { initializeApp } from "firebase/app"
//
// const config = {
//   apiKey: process.env.REACT_APP_FIRESTORE_API_KEY,
//   authDomain: process.env.REACT_APP_FIRESTORE_AUTH_DOMAIN,
//   projectId: process.env.REACT_APP_FIRESTORE_PROJECT_ID,
// };
// export const firebaseApp = initializeApp({config});
//

export type DirektcenterStreamDocument = {
  isActive: boolean;
  isVisitorPostingEnabled: boolean;
  isHighlightsBoxVisible: boolean;
  title: string;
  createdAt: Timestamp;
  parentSection: string;
  section: string;
};
